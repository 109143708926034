import { gql } from "@apollo/client";

export const CREATE_CONTACT = gql`
mutation createContacto ($ContactoInput: ContactoInput!){
  createContacto(data: $ContactoInput) {
    data {
      id
      attributes {
        name
      }
    }
  }
}`

export const GET_HOME = gql`
query getHome{
  rrsses {
    data {
      id
      attributes {
        key
        value
      }
    }
  }
  equipos {
    data {
      id
      attributes {
        image {
          data {
            id 
            attributes {
              name
              width
              height
              url
            }
          }
        }
        frame {
          data {
            id 
            attributes {
              name
              width
              height
              url
            }
          }
        }
        content
      }
    }
  }
  queHacemos {
    data {
      id
      attributes {
        title
        description
        imageDesktop {
          data {
            id 
            attributes {
              name
              width
              height
              url
            }
          }
        }
        imageMobile {
          data {
            id 
            attributes {
              name
              width
              height
              url
            }
          }
        }
        content
      }
    }
  }
}`