import { default as AboutUs_Cloud1 } from './AboutUs_Cloud1.png';
import { default as AboutUs_Cloud2 } from './AboutUs_Cloud2.png';

import { default as Logo_GC } from './Logo_GC.png';
import { default as MrGuayP } from './MrGuayP.png';
import { default as MusicalSong } from './MusicalSong.png';
import { default as GuaypesSmall } from './GuaypesSmall.png';

import { default as Welcome_Image } from './Welcome_Image.png';
import { default as ImageMB } from './Mobile_Home.png';
import { default as Welcome_Title } from './Welcome_Title.png';

import { default as WhatWeDo_Card1 } from './WhatWeDo_Card1.png';
import { default as WhatWeDo_Card2 } from './WhatWeDo_Card2.png';
import { default as WhatWeDo_Card3 } from './WhatWeDo_Card3.png';
import { default as WhatWeDo_Card4 } from './WhatWeDo_Card4.png';
import { default as WhatWeDo_Card5 } from './WhatWeDo_Card5.png';

import { default as WhatWeDo_Card1MB } from './Card1__MB.png';
import { default as WhatWeDo_Card2MB } from './Card2__MB.png';
import { default as WhatWeDo_Card3MB } from './Card3__MB.png';
import { default as WhatWeDo_Card4MB } from './Card4__MB.png';
import { default as WhatWeDo_Card5MB } from './Card5__MB.png';

import { default as Us_Resume } from './Us__Resume.png';

import { default as Us_Frame1 } from './Us__Frame1.png';
import { default as Us_Frame2 } from './Us__Frame2.png';
import { default as Us_Frame3 } from './Us__Frame3.png';

import { default as Us_Photo1 } from './Us__Photo1.png';
import { default as Us_Photo2 } from './Us__Photo2.png';
import { default as Us_Photo3 } from './Us__Photo3.png';

import { default as RRSS_FB } from './RRSS__FB.png'
import { default as RRSS_IG } from './RRSS__IG.png'
import { default as RRSS_SP } from './RRSS__SP.png'
import { default as RRSS_TT } from './RRSS__TT.png'
import { default as RRSS_YT } from './RRSS__YT.png'

export const Assets = {
    Common: {
        Logo: Logo_GC,
        MrGuayP,
        MusicalSong,
        GuaypesSmall
    },
    AboutUs: {
        Cloud1: AboutUs_Cloud1,
        Cloud2: AboutUs_Cloud2
    },
    Welcome: {
        Image: Welcome_Image,
        ImageMB,
        Title: Welcome_Title
    },
    WhatWeDo: {
        Card1: WhatWeDo_Card1,
        Card2: WhatWeDo_Card2,
        Card3: WhatWeDo_Card3,
        Card4: WhatWeDo_Card4,
        Card5: WhatWeDo_Card5,
        Card1MB: WhatWeDo_Card1MB,
        Card2MB: WhatWeDo_Card2MB,
        Card3MB: WhatWeDo_Card3MB,
        Card4MB: WhatWeDo_Card4MB,
        Card5MB: WhatWeDo_Card5MB,
    },
    Us: {
        Resume: Us_Resume,
        Frames: {
            Frame1: Us_Frame1,
            Frame2: Us_Frame2,
            Frame3: Us_Frame3
        },
        Photos: {
            Photo1: Us_Photo1,
            Photo2: Us_Photo2,
            Photo3: Us_Photo3
        }
    },
    RRSS: {
        FB: RRSS_FB,
        IG: RRSS_IG,
        SP: RRSS_SP,
        TT: RRSS_TT,
        YT: RRSS_YT,
    }
}