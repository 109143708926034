/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import YouTubePlaylist from '@codesweetly/react-youtube-playlist';
import { useForm, SubmitHandler } from "react-hook-form"
import { Parallax } from 'react-scroll-parallax';

import useIsMobile from './Hooks/useIsMobile';
import { Assets } from './Assets';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { ToastContainer, toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_CONTACT, GET_HOME } from './connections';

export function getOffset(el: any) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
    height: rect.height
  };
}


export function useHorizontalScroll(debug = true) {
  const elRef = useRef();

  useEffect(() => {
    const el = elRef.current as any;
    if (el) {
      const onWheel = (e: any) => {
        const rightScroll = document.getElementsByClassName('Website__Section__WhatWeDo')[0]

        const { top: blockedPosition } = getOffset(rightScroll);
        const currentPosition = window.pageYOffset

        const isBeginningHorizontal = (rightScroll.scrollLeft === 0)
        const isFinalHorizontal = (rightScroll.scrollLeft + rightScroll.clientWidth === rightScroll.scrollWidth)

        const isDown = e.deltaY > 0;
        const isUp = e.deltaY < 0;

        let excluded = true
        // let excluded = window.scrollY === Math.ceil(blockedPosition) || isFinalHorizontal
        
        const isMobile = window.innerWidth < 768
        if (isMobile) {
          return;
        }

        console.log({
          estasEnY: window.scrollY,
          estasEnX: window.scrollX,
          blockedPosition
        })
        
        // El scroll horizonral está al principio.
        if (isBeginningHorizontal) {
          // Validamos que el scroll no se mueva si no está en la sección
          if (currentPosition < blockedPosition) {
            if (debug) {
              console.info('El scroll horizontal está al principio y la posición actual es menor a la posición bloqueada. Permitimos.');
            }
            excluded = false
            return;
          }

          // Si el scroll está al principio, y va subiendo, lo permitimos
          if (isUp) {
            if (debug) {
              console.info('El scroll horizontal está al principio, y la fuerza es negativa (va subiendo). Permitimos.');
            }
            excluded = false
            return;
          }
        }

        if (isFinalHorizontal) {
          if (isDown) {
            if (debug) {
              console.info('El scroll horizontal está al final, y la fuerza es positiva (va bajando). Permitimos.');
            }
            excluded = false
            return;
          }

          if (isUp && currentPosition > blockedPosition) {
            if (debug) {
              console.info('El scroll horizontal está al final, y la fuerza es negativa (va subiendo), pero está bajo la zona de bloqueo. Permitimos.');
            }
            excluded = false
            return;
          }
        }

        // // Si el scroll está al final y va bajando, lo permitimos
        // if (isFinalHorizontal && isDown) return;

        // // Si el scroll está al final y va subiendo, lo permitimos
        // if (isUp && currentPosition > blockedPosition) return;

        if (excluded) {
          window.scrollTo({
            left: 0,
            top: currentPosition > blockedPosition ? blockedPosition + 1 : blockedPosition -1,
            // @ts-ignore
            behavior: 'instant'
          })
          
          e.preventDefault();
          e.stopPropagation();
          
          rightScroll.scrollLeft += e.deltaY;
        }
        return;
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return elRef;
}

type Inputs = {
  name: string
  mail: string
  type: string
  message: string
}

function App() {
  const isMobile = useIsMobile();
  const {
    register,
    handleSubmit,
    reset,
    // watch,
    formState: { errors },
  } = useForm<Inputs>()
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    submit({
      variables: {
        ContactoInput: data
      }
    })
  }

  const [rrss, setRRSS] = useState<{
    id?: number
    key: string
    value: string
  }[]>([])
  const [us, setUS] = useState<{
    image: string
    frame: string
    content: string
  }[]>([])
  const [cards, setCards] = useState<{
    title: string
    description: string
    imageMb: string
    imageDk: string
    content: string
  }[]>([])


  const { data, loading } = useQuery(GET_HOME)
  const [submit, e_Submit] = useMutation(CREATE_CONTACT)

  useEffect(() => {
    if (e_Submit?.data?.createContacto?.data?.id && !isNaN(e_Submit?.data?.createContacto?.data?.id)) {
      toast.success(`Solicitud enviada, pronto te respondará ${Math.round(Math.random() * 10) / 10 >= 0.5 ? 'Mr. Guay-P y Traperita' : 'Traperita y Mr. Guay-P'}`, {
        position: 'bottom-center'
      })
      reset()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_Submit.data])

  useEffect(() => {
    if (data?.equipos?.data && data?.queHacemos?.data && data?.rrsses?.data) {
      const { equipos, queHacemos, rrsses } = data

      setRRSS(rrsses?.data?.map((rrss: any) => {
        return {
          id: rrss.id,
          key: rrss.attributes.key,
          value: rrss.attributes.value,
        }
      }))

      setUS(equipos?.data?.map((equipo: any) => {
        return {
          image: equipo?.attributes?.image?.data?.attributes?.url,
          frame: equipo?.attributes?.frame?.data?.attributes?.url,
          content: equipo?.attributes?.content
        }
      }))

      setCards(queHacemos?.data?.map((queHacemo: any) => {
        let description = queHacemo?.attributes?.description.replaceAll('**', '*') as string
        const regexBold = description.match(/\*[^*]+\*/g) as any[]
        
        for (const reg of regexBold) {
          let t = reg.slice(1, reg.length - 1)
          description = description.replace(reg, `<strong>${t}</strong>`)
        }

        const regexItalic = description.match(/_(.+)_/g) as any[] || []
        
        for (const reg of regexItalic) {
          let t = reg.slice(1, reg.length - 1)
          description = description.replace(reg, `<i>${t}</i>`)
        }

        return {
          imageMb: queHacemo?.attributes?.imageMobile?.data?.attributes?.url,
          imageDk: queHacemo?.attributes?.imageDesktop?.data?.attributes?.url,
          content: queHacemo?.attributes?.content,
          description,
          title: queHacemo?.attributes?.title,
        }
      }))
    }
  }, [data])

  useEffect(() => {
    const _errors = Object.entries(errors)
    // console.log(_errors)
    if (_errors.length) {
      // toast.error(<>
      //   <ul>
      //     {_errors.map(_err => (
      //       <li>{_err[1].message}</li>
      //     ))}
      //   </ul>
      // </>, {
      //   position: 'bottom-center',
      //   style: {
      //     width: '50vw'
      //   }
      // })
    }
  }, [errors])

  if (loading || e_Submit.loading) {
    return <div className="MainLoader">
      <img src={Assets.Common.Logo} alt="Guaypes Club" />
      <small>Estamos cargando el sitio...</small>
    </div>
  }

  return (
    <div className={`Website ${isMobile ? 'isMobile' : 'isDesktop'}`} id="home">
      {/* Fondo de gradiente */}
      <div className="Website__LinearGradient" />

      {/* Menú superior de navegación */}
      <header className="Website__Header">
        <section className="Website__Header__Menu">
          <a href="#home">
            <img src={Assets.Common.Logo} alt="Logo de Guaypes Club" className='Website__Header__Menu__Logo' />
          </a>

          <nav className="Website__Header__Menu__Options">
            <ul>
                <li className="Website__Header__Menu__Option"><a href="#que-hacemos">¿Qué hacemos?</a></li>
                <li className="Website__Header__Menu__Option"><a href="#equipo">Equipo</a></li>
                <li className="Website__Header__Menu__Option"><a href="#escuchanos">Escúchanos</a></li>
                <li className="Website__Header__Menu__Option"><a href="https://www.musixmatch.com/artist/Guaypes-Club" target='_blank' rel="noreferrer">Letras</a></li>
            </ul>
          </nav>

          <div className="Website__Header__Menu__CTO">
            <a href="#contacto" className="Website__Header__Menu__CTO__Button">Contacto</a>
          </div>

        </section>
      </header>

      {/* Hero principal */}
      <section className="Website__Hero">
        {!isMobile && <div className="Website__Hero__Title">
          <img src={Assets.Welcome.Title} alt="Guaypes Club" className="Website__Hero__Title__Image" />
        </div>}
        <div className="Website__Hero__Content">
          <img src={isMobile ? Assets.Welcome.ImageMB : Assets.Welcome.Image} alt="Guaypes Club" className="Website__Hero__Content__Image" />
          <h2 className="Website__Hero__Content__Text">
            Somos una propuesta que parte 
            en el 2020 con <strong>música infantil
            innovadora</strong>, llena de <strong>trap y
            reggaeton consciente</strong> y con 
            perspectiva de género <strong>para
            niños y niñas</strong>, interpretado 
            por marionetas.</h2>
        </div>
        <section className="Website__Hero__Background" />
      </section>

      {/* Sección: Qué hacemos */}
      <h2 className="Utils__Hide">¿Qué hacemos?</h2>
      <section className="Website__Section__WhatWeDo" id="que-hacemos">
        {/* <Parallax translateX={[`${2200 + ((document.getElementsByTagName('body')[0].clientWidth - 392) * 3)}px`, `-${((document.getElementsByTagName('body')[0].clientWidth - 392) * 6) + 2300}px`]} shouldAlwaysCompleteAnimation > */}
        {/* Componente que crea las tarjetas */}
        {cards?.map((card, index) => <div className="Website__Section__WhatWeDo__Wrapper" key={`Card${index}`}>
          <div className="Website__Section__WhatWeDo__Wrapper__Inner">
          <h3 className='Website__Section__WhatWeDo__Wrapper__Description' dangerouslySetInnerHTML={{
            __html: card.description
          }} />
          <div className='Website__Section__WhatWeDo__Card'>
            <div className="Website__Section__WhatWeDo__Card__Image">
              <img src={isMobile ? card.imageMb : card.imageDk} alt={card.title} />
            </div>
            <div className='Website__Section__WhatWeDo__Card__Content'>
              <p dangerouslySetInnerHTML={{
                __html: card.content
              }} />
            </div>
          </div>
          </div>
        </div>)}
        {/* </Parallax> */}
      </section>

      {/* Sección: Nosotros */}
      <div className="Website__Section__Us__BackgroundClouds">
        {!isMobile ? <Parallax translateX={['-400px', '920px']}>
            <img src={Assets.AboutUs.Cloud1} alt='Nube decorativa 1' style={{ marginTop: '48px' }} />
        </Parallax> :
          <img src={Assets.AboutUs.Cloud1} alt='Nube decorativa 1' style={{ marginTop: '48px' }} />}
        {!isMobile ? <Parallax translateX={['1920px', '-400px',]}>
            <img src={Assets.AboutUs.Cloud2} alt='Nube decorativa 2' />
        </Parallax> : <img src={Assets.AboutUs.Cloud2} alt='Nube decorativa 2' />}
            {/* <img src={Assets.AboutUs.Cloud2} alt='Nube decorativa 2' /> */}
      </div>
      <section className="Website__Section__Us">
        <h2>Equipo</h2>
        <div className="Website__Section__Us__Cols" id="equipo">
          <div className="Website__Section__Us__Cols__Col Website__Section__Us__Cols__AboutUs">
              <img src={Assets.Us.Resume} alt="Resumen de Guaypes Club" />
              <p>Traperita, Mopita y Mr. Guay-P, son los protagonistas de esta banda y a través de sus experiencias, nos enseñan algo y nos hacen tomar conciencia sobre un tema.</p>
          </div>
          <div className="Website__Section__Us__Cols__Col Website__Section__Us__Cols__Pills">
              {/* Componente de Pills Naranjas */}
              <div className="Website__Section__Us__Cols__Pills__Wrapper">
                {us.map((item, index) => <div className="Website__Section__Us__Cols__Pill" key={`Pill${index}`}>
                  <img className="Website__Section__Us__Cols__Pill__Frame" src={item.frame} alt={`Marco ${index}`} />
                  <div className="Website__Section__Us__Cols__Pill__Image">
                    <img src={item.image} alt={`Foto ${index}`} />
                  </div>
                  <div className="Website__Section__Us__Cols__Pill__Content">
                    <p>{item.content}</p>
                  </div>
                </div>)}
              </div>
          </div>          
        </div>
      </section>

      {/* Sección: Escúchanos */}
      <div className="Website__Section__Listen" id="escuchanos">
        <div className="Website__Section__Listen__Background">
          <img src={Assets.AboutUs.Cloud1} alt='Nube decorativa 1' />
        </div>

        <h2>Escúchanos</h2>
        
        <div className="Website__Section__Listen__Wrapper">
        <p>Escucha nuestros sonidos frescos y nuestros fantásticos videos y Playlists musicales aquí, en Youtube y/o en Spotify.</p>
        <div className="Website__Section__Listen__Content">
        <YouTubePlaylist
          apiKey={process.env.REACT_APP_APIKEY as string}
          playlistId={process.env.REACT_APP_PLAYLISTID as string}
          uniqueName="GuaypesClubLastPlaylist"
        />

        <div className="GuaypesClubSpotify">
          <iframe
            title="Guaypes Club on Spotify"
            style={{ borderRadius: 12 }}
            src="https://open.spotify.com/embed/artist/6OmpXe9rFVRCcgjkxm7yMV?utm_source=generator"
            width="100%"
            height="480"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy" />
          <img src={Assets.Common.MrGuayP} alt="Mr. Guay-P" className="Website__Section__Listen__MrGuayP" />
        </div>

        </div>
      </div>
      </div>

      {/* Sección: Formulario de Contacto */}
      <section className="Website__Hero__Background" />
      <div className="Website__Section__Contact" id="contacto">
        <img src={Assets.Common.MusicalSong} className='Website__Section__Contact__MusicalSong' alt="" />
        <h2>Contáctanos</h2>

        <form className="Form" onSubmit={handleSubmit(onSubmit)}>
          <div className="Form__InputGroup">
            <label htmlFor="name">Tu nombre y apellido</label>
            <input id="name" type="text" {...register("name", {
              required: {
                value: true,
                message: 'Debes ingresar tu nombre completo.'
              }
            })}></input>
          </div>
          <div className="Form__InputGroup">
            <label htmlFor="mail">Correo electrónico</label>
            <input id="mail" type="text" {...register("mail", {
              required: {
                value: true,
                message: 'Debes ingresar tu correo electrónico.'
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Debes ingresar una dirección de correo electrónico válida.",
              },
            })}></input>
          </div>
          <div className="Form__InputGroup">
            <label htmlFor="type">Motivo de Contacto</label>
            <select id="type" defaultValue="" {...register("type", {
              required: {
                value: true,
                message: 'Debes seleccionar un motivo de contacto.'
              }
            })}>
              <option value="" disabled>Selecciona una opción...</option>
              <option value="consultas">Consultas y Sugerencias</option>
              <option value="felicitaciones">Felicitaciones</option>
              <option value="contrataciones">Eventos y Contrataciones</option>
            </select>
          </div>
          <div className="Form__InputGroup">
            <label htmlFor="message">Mensaje</label>
            <textarea id="message" {...register('message', {
              required: {
                value: true,
                message: 'Debes ingresar un mensaje.'
              }
            })}></textarea>
          </div>
          <div className="Form__InputGroup">
            <button>Enviar</button>
          </div>
        </form>
      </div>

      {/* Sección: Footer */}
      <div className="Website__Section__Listen__Background" />
      <footer className="Website__Section__Footer" id="footer">
          <img src={Assets.Common.Logo} alt="Logo de los Guaypes Club" />
          <img src={Assets.Common.GuaypesSmall} alt="Banda de los Guaypes Club" />
          <div className="Website__Section__Footer__SocialLinks">
            <h3>¡Síguenos en nuestras redes!</h3>
          <ul className="Website__Section__Footer__SocialLinks__List">
              <li>
                <a href={rrss.find(r => r.key === 'FB')?.value} target="_blank" rel="noreferrer">
                  <img className="Website__Section__Footer__SocialLinks__Image" alt="FB" src={Assets.RRSS.FB} />
                </a>
              </li>
              <li>
                <a href={rrss.find(r => r.key === 'IG')?.value} target="_blank" rel="noreferrer">
                  <img className="Website__Section__Footer__SocialLinks__Image" alt="IG" src={Assets.RRSS.IG} />
                </a>
              </li>
              <li>
                <a href={rrss.find(r => r.key === 'SP')?.value} target="_blank" rel="noreferrer">
                  <img className="Website__Section__Footer__SocialLinks__Image" alt="SP" src={Assets.RRSS.SP} />
                </a>
              </li>
              <li>
                <a href={rrss.find(r => r.key === 'TT')?.value} target="_blank" rel="noreferrer">
                  <img className="Website__Section__Footer__SocialLinks__Image" alt="TT" src={Assets.RRSS.TT} />
                </a>
              </li>
              <li>
                <a href={rrss.find(r => r.key === 'YT')?.value} target="_blank" rel="noreferrer">
                  <img className="Website__Section__Footer__SocialLinks__Image" alt="YT" src={Assets.RRSS.YT} />
                </a>
              </li>
            </ul>
            <small>Todos los derechos reservados &copy; Guaypes Club - 2023</small>
          </div>
      </footer>

      <ToastContainer />
    </div>
  );
}

export default App;
